import { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Icon,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { RiCloseCircleLine, RiPencilLine } from 'react-icons/ri';
import axios from 'axios';
import { DefaultLayout } from '../_layout/DefaultLayout';
import { AvatarDropzone } from '../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../components/Form/MaskedInput';
import { useAuth } from '../../../hooks/auth';
import { InternationalPhoneInput } from '../../../components/Form/InternationalPhoneInput';
import deleteUserAvatarsService from '../../../services/Users/DeleteUserAvatarsService';
import { updateUserAvatarsService } from '../../../services/Users/UpdateUserAvatarsService';
import { updateUsersProfileService } from '../../../services/Users/UpdateUsersProfileService';
import {
  maskCnpj,
  maskCpf,
  maskZipCode,
} from '../../../utils/formatters/handleMask';
import { translateError } from '../../../utils/errors';
import {
  validateCpf,
  validateBRPhone,
} from '../../../utils/documentValidation';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { IAddressBase } from '../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../components/AddressForm';

type ProfileFormData = {
  address?: IAddressBase;
  bio?: string;
  cpf?: string;
  cnpj?: string;
  companyName?: string;
  email: string;
  name: string;
  password?: string;
  passwordConfirmation?: string;
  phone?: string;
  tradeName?: string;
  featureGroupKey?: string;
};

const profileFormSchema = Yup.object().shape({
  address: Yup.object().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: addressValidation.required('Endereço requerido'),
    otherwise: Yup.object()
      .nullable()
      .transform((_, originalValue) => {
        const teste = Object.values(originalValue).some((key) => !!key)
          ? originalValue
          : null;

        return teste;
      }),
  }),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cpf: Yup.string()
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  cnpj: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('CNPJ requerido')
      .length(14, 'Valor inválido, requerido 14 dígitos')
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      ),
    otherwise: Yup.string()
      .length(14, 'Valor inválido, requerido 14 dígitos')
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      ),
  }),
  companyName: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('Nome da empresa requerido')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  featureGroupKey: Yup.string().required('Requerido'),
  name: Yup.string()
    .required('Nome requerido')
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'Nome inválido'),
  password: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  passwordConfirmation: Yup.string()
    .oneOf([null, Yup.ref('password')], 'Senhas não coincidem')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  phone: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('Telefone requerido')
      .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  tradeName: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const Profile = (): JSX.Element => {
  const { updateUser, user: authenticatedUser } = useAuth();
  const { push } = useHistory();
  const toast = useToast();

  const formMethods = useForm({
    resolver: yupResolver(profileFormSchema),
  });

  const { register, handleSubmit, formState, reset, control } = formMethods;

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState(authenticatedUser.avatarUrl);
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const { errors } = formState;

  useEffect(() => {
    reset({
      address: {
        city: authenticatedUser.address?.city,
        complement: authenticatedUser.address?.complement,
        country: authenticatedUser.address?.country,
        neighborhood: authenticatedUser.address?.neighborhood,
        number: authenticatedUser.address?.number,
        zipCode: authenticatedUser.address?.zipCode
          ? maskZipCode(authenticatedUser.address?.zipCode)
          : undefined,
        state: authenticatedUser.address?.state,
        street: authenticatedUser.address?.street,
      },
      bio: authenticatedUser.bio,
      cpf: authenticatedUser.cpf ? maskCpf(authenticatedUser.cpf) : undefined,
      cnpj: authenticatedUser.cnpj
        ? maskCnpj(authenticatedUser.cnpj)
        : undefined,
      companyName: authenticatedUser.companyName,
      email: authenticatedUser.email,
      name: authenticatedUser.name,
      phone: authenticatedUser.phone,
      tradeName: authenticatedUser.tradeName,
      featureGroupKey: authenticatedUser.featureGroup?.key,
    });
  }, [reset, authenticatedUser]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(authenticatedUser.id);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    delete authenticatedUser.avatar;
    delete authenticatedUser.avatarUrl;

    updateUser(authenticatedUser);
  }, [handleToggleDeleteConfirmationModal, updateUser, authenticatedUser]);

  const handleToggleInputEnable = useCallback(() => {
    setIsInputEnabled((prevState) => !prevState);
  }, []);

  const handleUpdateProfile: SubmitHandler<ProfileFormData> = useCallback(
    async ({
      address,
      bio,
      cpf,
      cnpj,
      companyName,
      email,
      name,
      password,
      passwordConfirmation,
      phone,
      tradeName,
    }) => {
      try {
        const updatedUser = await updateUsersProfileService({
          address,
          bio,
          cpf,
          cnpj,
          companyName,
          email,
          name,
          password,
          passwordConfirmation,
          phone,
          tradeName,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const userWithNewAvatar = await updateUserAvatarsService({
            userId: authenticatedUser.id,
            avatarData: formData,
          });

          updateUser(userWithNewAvatar);
        } else {
          updateUser(updatedUser);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'Seu perfil foi editado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/dashboard');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao editar seu perfil, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast, updateUser, authenticatedUser.id],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <FormProvider {...formMethods}>
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleUpdateProfile)}
        >
          <Flex justifyContent="space-between">
            <Heading size="lg" fontWeight="normal">
              Perfil
            </Heading>

            <Button
              size="sm"
              fontSize="sm"
              colorScheme={isInputEnabled ? 'red' : 'yellow'}
              color="white"
              onClick={handleToggleInputEnable}
              leftIcon={
                <Icon
                  as={!isInputEnabled ? RiPencilLine : RiCloseCircleLine}
                  fontSize="16"
                />
              }
            >
              {isInputEnabled ? 'Cancelar' : 'Editar'}
            </Button>
          </Flex>

          <Divider my="6" borderColor="gray.300" />

          <Flex justify="center" mb="8">
            <AvatarDropzone
              avatarUrl={avatarUrl}
              disabled={!isInputEnabled}
              onChange={handleChangeAvatar}
              onDelete={handleToggleDeleteConfirmationModal}
            />
          </Flex>

          <VStack spacing="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="6"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 8]}>
                <MaskedInput
                  isDisabled={!isInputEnabled}
                  label="Nome completo"
                  error={errors.name}
                  {...register('name')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 12, 4]}>
                {authenticatedUser.featureGroup.key.includes('SELLER') ? (
                  <MaskedInput
                    isDisabled={!isInputEnabled}
                    label="CNPJ"
                    mask="cnpj"
                    error={errors.cnpj}
                    {...register('cnpj')}
                  />
                ) : (
                  <MaskedInput
                    isDisabled={!isInputEnabled}
                    label="CPF"
                    mask="cpf"
                    error={errors.cpf}
                    {...register('cpf')}
                  />
                )}
              </GridItem>
            </Grid>
            <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
              <MaskedInput
                isDisabled={!isInputEnabled}
                label="E-mail"
                type="email"
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />

              <InternationalPhoneInput
                isDisabled={!isInputEnabled}
                label="Telefone"
                name="phone"
                control={control}
                error={errors.phone}
              />
            </SimpleGrid>
            {authenticatedUser.featureGroup.key.includes('SELLER') && (
              <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
                <MaskedInput
                  isDisabled={!isInputEnabled}
                  label="Nome da empresa"
                  error={errors.companyName}
                  {...register('companyName')}
                />

                <MaskedInput
                  isDisabled={!isInputEnabled}
                  label="Nome fantasia"
                  error={errors.tradeName}
                  {...register('tradeName')}
                />
              </SimpleGrid>
            )}
            <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
              <MaskedInput
                isDisabled={!isInputEnabled}
                as="textarea"
                minHeight="160px"
                resize="none"
                py="2"
                label="Biografia"
                error={errors.bio}
                {...register('bio')}
              />

              <VStack spacing="8">
                <MaskedInput
                  label="Senha"
                  type="password"
                  isDisabled={!isInputEnabled}
                  error={errors.password}
                  {...register('password')}
                />

                <MaskedInput
                  label="Confirmação de senha"
                  type="password"
                  isDisabled={!isInputEnabled}
                  error={errors.passwordConfirmation}
                  {...register('passwordConfirmation')}
                />
              </VStack>
            </SimpleGrid>

            {authenticatedUser.featureGroup.key.includes('SELLER') && (
              <>
                <Divider my="6" borderColor="gray.300" />

                <AddressForm />
              </>
            )}

            {isInputEnabled && (
              <ButtonGroup mt="12" justifyContent="flex-end" w="100%">
                <Button
                  colorScheme="blackAlpha"
                  onClick={handleToggleInputEnable}
                >
                  Cancelar
                </Button>

                <Button
                  type="submit"
                  colorScheme="green"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button>
              </ButtonGroup>
            )}
          </VStack>
        </Box>
      </FormProvider>
    </DefaultLayout>
  );
};
