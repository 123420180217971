import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
  Button,
  HStack,
  Text,
  useToast,
  Switch,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
  RiHandCoinLine,
  RiLink,
  RiStackLine,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { HandleBookingQueueModal } from '../../../../../../../../../../../components/HandleBookingQueueModal';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { WaveSideEnum } from '../../../../../../../../../../../models/activities';
import { UserExperience } from '../../../../../../../../../../../models/users';
import deleteActivitySchedulesService from '../../../../../../../../../../../services/Activities/DeleteActivitySchedulesService';
import { listActivitiesSchedulesService } from '../../../../../../../../../../../services/Activities/ListActivitySchedulesService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { maskMoney } from '../../../../../../../../../../../utils/formatters/handleMask';
import { BookingRegisterModal } from '../BookingRegisterModal';
import { ActivityScheduleBookingQueueModal } from './components/ActivityScheduleBookingQueueModal';
import { ActivityScheduleItemsModal } from './components/ActivityScheduleItemsModal';
import {
  HandleActivityScheduleModal,
  IActivitySchedule,
} from './components/HandleActivityScheduleModal';

export const ActivitySchedules = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { activity } = useActivity();

  const [activitySchedules, setActivitySchedules] = useState<
    IActivitySchedule[]
  >([]);
  const [showExpired, setShowExpired] = useState(false);
  const [handlingActivitySchedule, setHandlingActivitySchedule] =
    useState<IActivitySchedule>();
  const [isBookingRegisterModalVisible, setIsBookingRegisterModalVisible] =
    useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleActivityScheduleModalVisible,
    setIsHandleActivityScheduleModalVisible,
  ] = useState(false);
  const [
    isHandleActivityScheduleBookingQueueModalVisible,
    setIsHandleActivityScheduleBookingQueueModalVisible,
  ] = useState(false);
  const [
    isHandleScheduleItemsModalVisible,
    setIsHandleScheduleItemsModalVisible,
  ] = useState(false);
  const [
    isHandleBookingQueueModalVisible,
    setIsHandleBookingQueueModalVisible,
  ] = useState(false);

  const toggleBookingRegisterModal = useCallback(
    (activitySchedule?: IActivitySchedule) => {
      setHandlingActivitySchedule(activitySchedule);

      setIsBookingRegisterModalVisible((prevState) => !prevState);
    },
    [],
  );

  const toggleDeleteConfirmationModal = useCallback(
    (activitySchedule?: IActivitySchedule) => {
      setHandlingActivitySchedule(activitySchedule);

      setIsDeleteConfirmationModalVisible((prevState) => !prevState);
    },
    [],
  );

  const toggleHandleActivityScheduleModal = useCallback(
    (activitySchedule?: IActivitySchedule) => {
      setHandlingActivitySchedule(activitySchedule);

      setIsHandleActivityScheduleModalVisible((prevState) => !prevState);
    },
    [],
  );

  const toggleHandleActivityScheduleBookingQueueModal = useCallback(
    (activitySchedule?: IActivitySchedule) => {
      setHandlingActivitySchedule(activitySchedule);

      setIsHandleActivityScheduleBookingQueueModalVisible(
        (prevState) => !prevState,
      );
    },
    [],
  );

  const toggleHandleScheduleItemsModal = useCallback(
    (activitySchedule?: IActivitySchedule) => {
      setHandlingActivitySchedule(activitySchedule);

      setIsHandleScheduleItemsModalVisible((prevState) => !prevState);
    },
    [],
  );

  const toggleHandleBookingQueueModal = useCallback(() => {
    setIsHandleBookingQueueModalVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    async function loadSchedules(): Promise<void> {
      if (activity) {
        try {
          const schedulesList = await listActivitiesSchedulesService({
            activityId: activity.id,
            showExpired,
          });

          setActivitySchedules(
            schedulesList.items.map((activitySchedule) => {
              const [startDateYear, startDateMonth, startDateDay] =
                activitySchedule.startDate.split('-');
              const [endDateYear, endDateMonth, endDateDay] =
                activitySchedule.endDate?.split('-') || [];

              return {
                ...activitySchedule,
                formattedEndDate: activitySchedule.endDate
                  ? `${endDateDay}/${endDateMonth}/${endDateYear.slice(2)}`
                  : '-',
                formattedEndTime: activitySchedule.endTime.slice(0, 5),
                formattedStartDate: `${startDateDay}/${startDateMonth}/${startDateYear.slice(
                  2,
                )}`,
                formattedStartTime: activitySchedule.startTime.slice(0, 5),
                formattedBookingPrice: maskMoney(
                  activitySchedule.bookingPrice ?? 0,
                  { hideSymbol: true },
                ),
                formattedGuestAdditionalBookingPrice: maskMoney(
                  activitySchedule.guestAdditionalBookingPrice ?? 0,
                  { hideSymbol: true },
                ),
              };
            }),
          );
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao carregar dados',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao carregar os eventos, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    }

    loadSchedules();
  }, [activity, showExpired, toast]);

  const handleActivityScheduleModalSave = (
    activitySchedule: IActivitySchedule,
    action: 'create' | 'update',
  ): void => {
    switch (action) {
      case 'create':
        setActivitySchedules((prevState) => [activitySchedule, ...prevState]);

        break;

      case 'update':
        setActivitySchedules((prevState) =>
          prevState.map((schedule) =>
            schedule.id === activitySchedule.id
              ? { ...schedule, ...activitySchedule }
              : schedule,
          ),
        );

        break;

      default:
        break;
    }

    setHandlingActivitySchedule(undefined);
    toggleHandleActivityScheduleModal();
  };

  const handleDeleteActivitySchedule = useCallback(
    async (activityScheduleId: string) => {
      if (activity) {
        try {
          await deleteActivitySchedulesService(activityScheduleId);

          setHandlingActivitySchedule(undefined);

          setActivitySchedules((prevState) =>
            prevState.filter((schedule) => schedule.id !== activityScheduleId),
          );

          toast({
            title: 'Excluído com sucesso',
            description: 'O evento foi excluído corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toggleDeleteConfirmationModal();

            toast({
              title: 'Falha ao excluir',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao excluir o evento, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [activity, toast, toggleDeleteConfirmationModal],
  );

  const handleCopyBookingUrl = useCallback(
    async (bookingUrl: string) => {
      try {
        await navigator.clipboard.writeText(bookingUrl);

        toast({
          title: 'Copiado com sucesso',
          description: 'O link foi copiado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao copiar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao copiar o link, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  return (
    <Box {...props}>
      {handlingActivitySchedule && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            toggleDeleteConfirmationModal();
          }}
          onConfirm={() =>
            handleDeleteActivitySchedule(handlingActivitySchedule.id)
          }
          title="Confirmar exclusão"
          message="Deseja realmente excluir?"
        />
      )}

      <HandleActivityScheduleModal
        activitySchedule={handlingActivitySchedule}
        isOpen={isHandleActivityScheduleModalVisible}
        onClose={() => {
          setHandlingActivitySchedule(undefined);
          toggleHandleActivityScheduleModal();
        }}
        onSave={handleActivityScheduleModalSave}
      />

      {!!handlingActivitySchedule?.id && (
        <ActivityScheduleBookingQueueModal
          scheduleId={handlingActivitySchedule.id}
          isOpen={isHandleActivityScheduleBookingQueueModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            toggleHandleActivityScheduleBookingQueueModal();
          }}
        />
      )}

      {!!handlingActivitySchedule?.id && (
        <ActivityScheduleItemsModal
          scheduleId={handlingActivitySchedule.id}
          isOpen={isHandleScheduleItemsModalVisible}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            toggleHandleScheduleItemsModal();
          }}
        />
      )}

      {!!activity && (
        <HandleBookingQueueModal
          activity={activity}
          isOpen={isHandleBookingQueueModalVisible}
          onClose={toggleHandleBookingQueueModal}
        />
      )}

      {!!handlingActivitySchedule && (
        <BookingRegisterModal
          activityScheduleId={handlingActivitySchedule.id}
          minExperience={handlingActivitySchedule.minUserExperience}
          isOpen={isBookingRegisterModalVisible}
          startDate={handlingActivitySchedule.startDate}
          onClose={() => {
            setHandlingActivitySchedule(undefined);
            toggleBookingRegisterModal();
          }}
        />
      )}

      <HStack justify="space-between" px="4">
        <HStack>
          <Switch
            name="showExpired"
            id="showExpired"
            isChecked={showExpired}
            onChange={() => setShowExpired((prevState) => !prevState)}
          />

          <Text htmlFor="showExpired">Exibir expirados</Text>
        </HStack>

        <ButtonGroup justifyContent="flex-end">
          {/* {authenticatedUser.featureGroup.features.some((feature) =>
            [
              'BOOKING_QUEUE_FULL_ACCESS',
              'BOOKING_QUEUE_WRITE_ACCESS',
            ].includes(feature.key),
          ) && (
            <Button
              colorScheme="green"
              size="sm"
              leftIcon={<Icon as={RiAddLine} fontSize="16" />}
              onClick={toggleHandleBookingQueueModal}
            >
              Reserva na fila
            </Button>
          )} */}

          {authenticatedUser.featureGroup.features.some((feature) =>
            [
              'ACTIVITY_SCHEDULE_FULL_ACCESS',
              'ACTIVITY_SCHEDULE_WRITE_ACCESS',
            ].includes(feature.key),
          ) && (
            <Button
              colorScheme="green"
              size="sm"
              leftIcon={<Icon as={RiAddLine} fontSize="16" />}
              onClick={() => toggleHandleActivityScheduleModal(undefined)}
            >
              Novo horário
            </Button>
          )}
        </ButtonGroup>
      </HStack>

      <Table colorScheme="blue" mt="4" fontSize="sm">
        <Thead>
          <Tr>
            <Th textAlign="center">Ativo</Th>
            <Th>Período</Th>
            <Th>Horário</Th>
            <Th>Dias da semana</Th>
            <Th>Vagas</Th>
            <Th>Modalidade</Th>
            <Th>Lado da onda</Th>
            <Th>Mín. Experiência</Th>
            <Th>Valor R$</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {activitySchedules.map((activitySchedule) => (
            <Tr key={activitySchedule.id}>
              <Td textAlign="center">
                {activitySchedule.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td>
                <HStack spacing="2">
                  <Text fontSize="xs" color="gray.400">
                    De:
                  </Text>
                  <Text>{activitySchedule.formattedStartDate}</Text>
                </HStack>

                <HStack spacing="2">
                  <Text fontSize="xs" color="gray.400">
                    Até:
                  </Text>
                  <Text>{activitySchedule.formattedEndDate}</Text>
                </HStack>
              </Td>

              <Td>
                <HStack spacing="2">
                  <Text fontSize="xs" color="gray.400">
                    De:
                  </Text>
                  <Text>
                    {activitySchedule.formattedStartTime ?? 'Ilimitado'}
                  </Text>
                </HStack>

                <HStack spacing="2">
                  <Text fontSize="xs" color="gray.400">
                    Até:
                  </Text>
                  <Text>{activitySchedule.formattedEndTime}</Text>
                </HStack>
              </Td>

              <Td>
                <HStack spacing="2">
                  {['D', 'S', 'T', 'Q', 'Q', 'S', 'S'].map((weekDay, index) => (
                    <Text
                      key={weekDay.concat(String(index))}
                      color={
                        activitySchedule.daysOfWeek.some(
                          (scheduleDay) => scheduleDay === index,
                        )
                          ? 'gray.700'
                          : 'gray.400'
                      }
                    >
                      {weekDay}
                    </Text>
                  ))}
                </HStack>
              </Td>

              <Td>
                <HStack spacing="2">
                  <Text>{activitySchedule.totalVacancies ?? 'Ilimitado'}</Text>

                  <Text color="gray.400">
                    {`(${activitySchedule.guestVacancies}`}
                  </Text>
                  <Text fontSize="xs" color="gray.400">
                    {' Conv.)'}
                  </Text>
                </HStack>

                {authenticatedUser.featureGroup.features.some(
                  (feature) => feature.key === 'LODGER_VACANCIES_FULL_ACCESS',
                ) && (
                  <HStack spacing="2">
                    <Text>{activitySchedule.lodgerVacancies}</Text>

                    <Text fontSize="xs" color="gray.400">
                      Hóspedes
                    </Text>
                  </HStack>
                )}
              </Td>

              <Td>{activitySchedule.modality?.title || '-'}</Td>

              <Td>
                {activitySchedule.waveSide
                  ? WaveSideEnum[activitySchedule.waveSide]
                  : '-'}
              </Td>

              <Td>{UserExperience[activitySchedule.minUserExperience]}</Td>

              <Td>
                <HStack spacing="2">
                  <Text fontSize="xs" color="gray.400">
                    Reserva:
                  </Text>
                  <Text>{activitySchedule.formattedBookingPrice}</Text>
                </HStack>

                <HStack spacing="2">
                  <Text fontSize="xs" color="gray.400">
                    Conv.:
                  </Text>

                  <HStack spacing="2">
                    <Text>+</Text>
                    <Text>
                      {activitySchedule.formattedGuestAdditionalBookingPrice}
                    </Text>
                  </HStack>
                </HStack>
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'BOOKING_QUEUE_FULL_ACCESS',
                      'BOOKING_QUEUE_READ_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Schedule items"
                      size="sm"
                      icon={<Icon as={RiStackLine} />}
                      fontSize="16"
                      colorScheme="purple"
                      onClick={() =>
                        toggleHandleActivityScheduleBookingQueueModal(
                          activitySchedule,
                        )
                      }
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    ['BOOKING_FULL_ACCESS', 'BOOKING_WRITE_ACCESS'].includes(
                      feature.key,
                    ),
                  ) &&
                    !activitySchedule.isExpired && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="green"
                        onClick={() =>
                          toggleBookingRegisterModal(activitySchedule)
                        }
                      >
                        Reservar
                      </Button>
                    )}

                  {!activitySchedule.isExpired &&
                    activitySchedule.allowLinkBookings && (
                      <IconButton
                        aria-label="Copy booking link"
                        size="sm"
                        icon={<Icon as={RiLink} />}
                        fontSize="16"
                        colorScheme="blue"
                        onClick={() =>
                          handleCopyBookingUrl(activitySchedule.bookingUrl)
                        }
                      />
                    )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_ITEM_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_ITEM_WRITE_ACCESS',
                      'ACTIVITY_SCHEDULE_ITEM_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Schedule items"
                      size="sm"
                      icon={<Icon as={RiHandCoinLine} />}
                      fontSize="16"
                      colorScheme="blue"
                      onClick={() =>
                        toggleHandleScheduleItemsModal(activitySchedule)
                      }
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_WRITE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      icon={<Icon as={RiEditLine} />}
                      fontSize="16"
                      color="white"
                      colorScheme="yellow"
                      onClick={() =>
                        toggleHandleActivityScheduleModal(activitySchedule)
                      }
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_SCHEDULE_FULL_ACCESS',
                      'ACTIVITY_SCHEDULE_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<Icon as={RiDeleteBinLine} />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() =>
                        toggleDeleteConfirmationModal(activitySchedule)
                      }
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
