import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import { translateError } from '../../../../../utils/errors';
import {
  IFeatureGroupListItem,
  listFeatureGroupsService,
} from '../../../../../services/FeatureGroups/ListFeatureGroupsService';
import {
  validateCnpj,
  validateCpf,
  validateBRPhone,
} from '../../../../../utils/documentValidation';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { useAuth } from '../../../../../hooks/auth';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';

type NewUserFormData = {
  address?: IAddressBase;
  bio?: string;
  cpf?: string;
  cnpj?: string;
  companyName?: string;
  email: string;
  name: string;
  phone?: string;
  tradeName?: string;
  featureGroupKey: string;
  ventureId: string;
};

const registerUserFormSchema = Yup.object().shape({
  address: Yup.object().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: addressValidation.required('Endereço requerido'),
    otherwise: Yup.object()
      .nullable()
      .transform((_, originalValue) => {
        const teste = Object.values(originalValue).some((key) => !!key)
          ? originalValue
          : null;

        return teste;
      }),
  }),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  cpf: Yup.string()
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  cnpj: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('CNPJ requerido')
      .length(14, 'Valor inválido, requerido 14 dígitos')
      .test('is-valid', 'CNPJ inválido', (value) => validateCnpj(value))
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      ),
    otherwise: Yup.string()
      .length(14, 'Valor inválido, requerido 14 dígitos')
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      ),
  }),
  companyName: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('Nome da empresa requerido')
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  featureGroupKey: Yup.string().required('Requerido'),
  name: Yup.string()
    .required('Nome requerido')
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'Nome inválido'),
  phone: Yup.string().when('featureGroupKey', {
    is: (val?: string) => val === 'SELLER',
    then: Yup.string()
      .required('Telefone requerido')
      .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.string()
      .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  tradeName: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ventureId: Yup.string().required('Requerido'),
});

export const UserRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [featureGroups, setFeatureGroups] = useState<IFeatureGroupListItem[]>(
    [],
  );
  const [featureGroupsSelectOptions, setFeatureGroupsSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const formMethods = useForm({
    resolver: yupResolver(registerUserFormSchema),
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setError,
    setValue,
  } = formMethods;

  const { errors } = formState;

  const selectedFeatureGroupKey = watch('featureGroupKey');

  const selectedFeatureGroupId = useMemo(
    () =>
      featureGroups.find(
        (featureGroup) => featureGroup.key === selectedFeatureGroupKey,
      )?.id,
    [featureGroups, selectedFeatureGroupKey],
  );

  useEffect(() => {
    async function loadFeatureGroups(): Promise<void> {
      const { items: featureGroupList } = await listFeatureGroupsService({});

      const forbiddenFeatureGroups = [
        'APP_STORE_USER',
        'DEPENDANT',
        'MEMBER',
        'GUEST',
        'LODGER',
      ];

      const validFeatureGroups = featureGroupList.filter(
        (featureGroup) =>
          featureGroup.authLevel >= authenticatedUser.featureGroup.authLevel &&
          !forbiddenFeatureGroups.some((featureGroupKey) =>
            featureGroup.key.includes(featureGroupKey),
          ),
      );

      const parsedFeatureGroupsSelectOptions = validFeatureGroups.map(
        (featureGroup) => ({
          label: featureGroup.name,
          value: featureGroup.key,
        }),
      );

      setFeatureGroups(validFeatureGroups);
      setFeatureGroupsSelectOptions(parsedFeatureGroupsSelectOptions);
    }

    loadFeatureGroups();
  }, [authenticatedUser.featureGroup.authLevel]);

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      const venturesData: SelectOption[] = ventures.map((venture) => ({
        label: venture.name,
        value: venture.id,
      }));

      setVenturesSelectOptions(venturesData);
    }

    if (!authenticatedUser.ventureId) {
      loadVentures();
    } else {
      setValue('ventureId', authenticatedUser.ventureId);
    }
  }, [setValue, authenticatedUser.ventureId]);

  useEffect(() => {
    if (selectedFeatureGroupKey !== 'SELLER') {
      setValue('companyName', undefined);
      setValue('tradeName', undefined);
      setValue('cnpj', undefined);
    }
  }, [selectedFeatureGroupKey, setValue]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewUser: SubmitHandler<NewUserFormData> = useCallback(
    async ({
      address,
      cnpj,
      companyName,
      bio,
      cpf,
      email,
      name,
      phone,
      tradeName,
      ventureId,
    }) => {
      const cleanVentureId = ventureId.replace(/\s/g, '');

      if (selectedFeatureGroupKey !== 'MASTER' && !cleanVentureId) {
        setError('ventureId', {
          message: 'Requerido especificar um empreendimento',
        });

        return;
      }

      try {
        const newUser = await createUsersService({
          address,
          bio,
          cnpj,
          companyName,
          cpf,
          email,
          name,
          phone,
          tradeName,
          featureGroupId: selectedFeatureGroupId,
          ventureId: cleanVentureId || null,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateUserAvatarsService({
            avatarData: formData,
            userId: newUser.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O funcionário foi cadastrado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/users/details', { userId: newUser.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o funcionário, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [
      avatar,
      push,
      selectedFeatureGroupId,
      selectedFeatureGroupKey,
      setError,
      toast,
    ],
  );

  return (
    <DefaultLayout>
      <FormProvider {...formMethods}>
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleNewUser)}
        >
          <Heading size="lg" fontWeight="normal">
            Cadastrar funcionário
          </Heading>

          <Divider my="6" borderColor="gray.300" />

          <Flex justify="center" mb="8">
            <AvatarDropzone
              avatarUrl={avatarUrl}
              onChange={handleChangeAvatar}
              onDelete={handleDeleteAvatar}
            />
          </Flex>

          <VStack spacing="8">
            {!authenticatedUser.ventureId ? (
              <Grid
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(1, 1fr)',
                  'repeat(12, 1fr)',
                ]}
                gap="6"
                width="100%"
              >
                <GridItem colSpan={[12, 12, 4]}>
                  <ReactSelect
                    label="Tipo"
                    name="featureGroupKey"
                    options={featureGroupsSelectOptions}
                    control={control}
                    error={errors.featureGroupKey}
                  />
                </GridItem>

                <GridItem colSpan={[12, 12, 8]}>
                  <ReactSelect
                    label="Empreendimento"
                    name="ventureId"
                    isDisabled={!selectedFeatureGroupId}
                    options={
                      selectedFeatureGroupKey === 'MASTER'
                        ? [
                            { label: 'Todos', value: ' ' },
                            ...venturesSelectOptions,
                          ]
                        : venturesSelectOptions
                    }
                    control={control}
                    error={errors.ventureId}
                  />
                </GridItem>
              </Grid>
            ) : (
              <ReactSelect
                label="Tipo"
                name="featureGroupKey"
                options={featureGroupsSelectOptions}
                control={control}
                error={errors.featureGroupKey}
              />
            )}

            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="6"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 8]}>
                <MaskedInput
                  label="Nome completo"
                  isDisabled={!selectedFeatureGroupId}
                  error={errors.name}
                  {...register('name')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 12, 4]}>
                {selectedFeatureGroupKey === 'SELLER' ? (
                  <MaskedInput
                    label="CNPJ"
                    mask="cnpj"
                    isDisabled={!selectedFeatureGroupId}
                    error={errors.cnpj}
                    {...register('cnpj')}
                  />
                ) : (
                  <MaskedInput
                    label="CPF"
                    mask="cpf"
                    isDisabled={!selectedFeatureGroupId}
                    error={errors.cpf}
                    {...register('cpf')}
                  />
                )}
              </GridItem>
            </Grid>

            <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
              <MaskedInput
                label="E-mail"
                type="email"
                isDisabled={!selectedFeatureGroupId}
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />

              <InternationalPhoneInput
                label="Telefone"
                name="phone"
                isDisabled={!selectedFeatureGroupId}
                control={control}
                error={errors.phone}
              />
            </SimpleGrid>

            {selectedFeatureGroupKey === 'SELLER' && (
              <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
                <MaskedInput
                  label="Nome da empresa"
                  isDisabled={!selectedFeatureGroupId}
                  error={errors.companyName}
                  {...register('companyName')}
                />

                <MaskedInput
                  label="Nome fantasia"
                  isDisabled={!selectedFeatureGroupId}
                  error={errors.tradeName}
                  {...register('tradeName')}
                />
              </SimpleGrid>
            )}

            <MaskedInput
              label="Biografia"
              isDisabled={!selectedFeatureGroupId}
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.bio}
              {...register('bio')}
            />
          </VStack>

          {selectedFeatureGroupKey === 'SELLER' && (
            <>
              <Divider my="6" borderColor="gray.300" />

              <AddressForm />
            </>
          )}

          <Flex mt="12" justify="flex-end">
            <ButtonGroup>
              <Button colorScheme="blackAlpha" onClick={goBack}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      </FormProvider>
    </DefaultLayout>
  );
};
