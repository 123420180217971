import {
  IBookingBase,
  IBookingCheckinBase,
  IBookingPaymentBase,
} from './bookings';
import { IEntityBase } from './entityBase';
import { IFeatureGroupBase } from './features';
import { IUserBase, UserExperience } from './users';

export enum WaveSideEnum {
  COMBO = 'Combo',
  LEFT = 'Esquerda',
  RIGHT = 'Direita',
}

export interface IActivityScheduleBlockBase extends IEntityBase {
  blockedDate: string;
  activityScheduleId: string;
}

export interface IWeekdayActivityScheduleCheckin extends IBookingCheckinBase {
  user: IUserBase;
}

interface IEventScheduleBookingUser extends IUserBase {
  host?: IUserBase;
  featureGroup: IFeatureGroupBase;
}

export interface IEventsScheduleBooking extends IBookingBase {
  checkins: IWeekdayActivityScheduleCheckin[];
  payment?: IBookingPaymentBase;
  user: IEventScheduleBookingUser;
}

export interface IEventsBookingQueues extends IBookingBase {
  id: string;
  user: IUserBase;
  titularUser: IUserBase;
  bookedDate: string;
  experience: UserExperience;
  priorizedAt: string | undefined;
}

export interface IActivityScheduleItemBase extends IEntityBase {
  activityScheduleId: string;
  description?: string;
  id: string;
  isActive: boolean;
  isRequired: boolean;
  name: string;
  price?: number | null;
  totalAmount: number;
  userId?: string | null;
}

export interface IWeekdayActivityScheduleBase {
  activityScheduleId: string;
  booked: number;
  guestBooked: number;
  lodgerBooked: number;
  bookingPrice?: number;
  guestAdditionalBookingPrice?: number;
  bookings: IEventsScheduleBooking[];
  currentUserBooked: boolean;
  date: string;
  endTime: string;
  experience: UserExperience;
  isActive: boolean;
  isExpired: boolean;
  modalityId: string;
  modalityTitle: string;
  scheduleBookingPrice?: number;
  startDate: string;
  startTime: string;
  guestVacancies: number;
  lodgerVacancies: number;
  isLodgerVacanciesExclusive: boolean;
  vacancies: number;
  waveSide?: 'COMBO' | 'LEFT' | 'RIGHT';
}

export interface IActivityScheduleBase extends IEntityBase {
  activityId: string;
  allowLinkBookings: boolean;
  allowPartialTimeBookings: boolean;
  bookingPrice?: number;
  guestAdditionalBookingPrice?: number;
  bookingUrl: string;
  daysOfWeek: Array<number>;
  endDate?: string;
  endTime: string;
  guestVacancies: number;
  lodgerVacancies: number;
  isActive: boolean;
  isExpired: boolean;
  minUserExperience: UserExperience;
  modalityId?: string;
  waveSide?: 'COMBO' | 'LEFT' | 'RIGHT';
  startDate: string;
  startTime: string;
  totalVacancies?: number;
}

export interface IActivityItemBase extends IEntityBase {
  description?: string;
  isActive: boolean;
  name: string;
  // totalAmount: number;
}

export interface IActivityBase extends IEntityBase {
  avatar?: string;
  avatarUrl?: string;
  bookingPrice?: number;
  description?: string;
  isActive: boolean;
  name: string;
  spotId: string;
}
