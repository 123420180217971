import {
  Link as ChakraLink,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

interface IProfileProps {
  showProfileData?: boolean;
}

export const Profile = ({ showProfileData }: IProfileProps): JSX.Element => {
  const { signOut, user: authenticatedUser } = useAuth();

  return (
    <Flex align="center">
      {showProfileData && (
        <Box mr="4" textAlign="right">
          <Text color="gray.500">{authenticatedUser.name}</Text>
          <Text fontSize="sm">{authenticatedUser.email}</Text>
        </Box>
      )}

      <ChakraLink as={Link} to="/profile">
        <Avatar
          size="md"
          name={authenticatedUser.name}
          src={authenticatedUser.avatarUrl}
        />
      </ChakraLink>

      <Button
        bg="transparent"
        ml="4"
        p="0"
        _hover={{
          bg: 'transparent',
          color: 'gray.500',
        }}
        onClick={signOut}
      >
        Sair
        <Icon as={RiLogoutBoxLine} fontSize="24" ml="2" />
      </Button>
    </Flex>
  );
};
