import {
  IActivityBase,
  IActivityItemBase,
  IActivityScheduleBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { IFeatureGroupBase } from '../../models/features';
import { ISpotModalityBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import { IUserCategoryBase } from '../../models/ventures';
import { IDetailedActivitySpot } from '../Activities/ShowActivitiesService';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDetailedActivity extends IActivityBase {
  spot: IDetailedActivitySpot;
  items: IActivityItemBase[];
}

interface IDetailedTitularUser extends IUserBase {
  category: IUserCategoryBase;
}

export interface IDetailedBookingQueueActivityScheduleItem
  extends IActivityScheduleItemBase {
  user?: IUserBase;
}

export interface IBookingQueueUser extends IUserBase {
  featureGroup: IFeatureGroupBase;
  host?: IUserBase;
}

export interface IBookingQueue extends IBookingQueueBase {
  user: IBookingQueueUser;
  titularUser: IDetailedTitularUser;
  activity: IDetailedActivity;
  activitySchedule: IActivityScheduleBase;
  modalities: ISpotModalityBase[];
  scheduleItems: IDetailedBookingQueueActivityScheduleItem[];
}

interface IListBookingQueueProps {
  activityId?: string;
  activityName?: string;
  activityScheduleId?: string;
  bookedDate?: string;
  showQueuedBookingsOnly?: boolean;
  spotName?: string;
  userDocument?: string;
  userName?: string;
}

export const listBookingQueuesService = async (
  filters: IListBookingQueueProps,
): Promise<IBookingQueue[]> => {
  const { data } = await jhsfBookingsApi.get<IBookingQueue[]>(
    '/booking-queues',
    {
      params: {
        activityName: filters.activityName || undefined,
        activityId: filters.activityId || undefined,
        activityScheduleId: filters.activityScheduleId,
        bookedDate: filters.bookedDate || undefined,
        showQueuedBookingsOnly: filters.showQueuedBookingsOnly,
        spotName: filters.spotName || undefined,
        userDocument: filters.userDocument || undefined,
        userName: filters.userName || undefined,
      },
    },
  );

  return data;
};
