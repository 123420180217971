import { extendTheme } from '@chakra-ui/react';

import styles from './styles';

import fonts from './foundations/fonts';

const overrides = {
  styles,
  fonts,
  components: {
    Modal: {
      sizes: {
        '7xl': {
          dialog: { maxWidth: '80rem' },
        },
      },
    },
  },
};

export default extendTheme(overrides);
