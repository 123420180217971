import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showUsersService } from '../../../../../services/Users/ShowUsersService';
import { translateError } from '../../../../../utils/errors';
import { useUser } from '../../../../../hooks/user';
import LodgerDetailsData from './components/LodgerDetailsData';
import { LodgerDetailsInfo } from './components/LodgerDetailsInfo';

interface ILocationState {
  lodgerId: string;
}

export const LodgerDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleUser: handleLodger, user: lodger } = useUser();

  const {
    state: { lodgerId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadLodger(): Promise<void> {
      try {
        const lodgerData = await showUsersService(lodgerId);

        handleLodger(lodgerData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do hóspede, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadLodger();

    return () => handleLodger(undefined);
  }, [lodgerId, handleLodger, toast]);

  return (
    <DefaultLayout>
      {lodger && (
        <Flex direction="column" w="100%">
          <LodgerDetailsInfo minW="510px" />

          <LodgerDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
