import { Flex, FlexProps } from '@chakra-ui/react';
import { LodgerDetailsTabs } from './components/LodgerDetailsTabs';

const LodgerDetailsData = (props: FlexProps): JSX.Element => (
  <Flex
    direction="column"
    w="100%"
    borderRadius={8}
    bg="white"
    p="8"
    {...props}
  >
    <LodgerDetailsTabs />
  </Flex>
);

export default LodgerDetailsData;
