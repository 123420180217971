import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  Flex,
  VStack,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import {
  FriendlyForeignerUserDocumentType,
  UserExperience,
} from '../../../../../../../models/users';
import { IUserListItem } from '../../../../../../../services/Users/ListUsersService';
import FaceIdStatusBadge from '../../../../components/FaceIdStatusBadge';

export interface ILodger extends IUserListItem {
  formattedDate: string;
}

interface ILodgerTableProps extends BoxProps {
  lodgers: ILodger[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt') => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
  totalItems?: number;
}

export const LodgerTable = ({
  lodgers,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  totalItems,
  ...rest
}: ILodgerTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
      {
        label: 'CPF',
        value: 'cpf',
      },
      {
        label: 'Experiência',
        value: 'experience',
      },
    ],
    [],
  );

  const filterSelectOptions = [
    {
      label: 'Todos',
      value: -1,
    },
    {
      label: 'Nível 1 e 2',
      value: 0,
    },
    {
      label: 'Nível 3 e 4',
      value: 1,
    },
    {
      label: 'Nível 5',
      value: 2,
    },
    {
      label: 'Nível 6',
      value: 3,
    },
    {
      label: 'Nível 6 e 7',
      value: 4,
    },
    {
      label: 'Nível 7',
      value: 5,
    },
    {
      label: 'Nível 8',
      value: 6,
    },
    {
      label: 'Especial',
      value: 7,
    },
  ];

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Telefone',
        value: 'phone',
      },
      {
        label: 'E-mail',
        value: 'email',
      },
      {
        label: 'CPF',
        value: 'cpf',
      },
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <Flex align="flex-end" justify="space-between">
        <TableFilters
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          filterInputType={filterBy === 'experience' ? 'select' : 'input'}
          filterSelectOptions={filterSelectOptions}
          onChangeFilterBy={onChangeFilterBy}
          onFilter={onFilter}
          onOrder={onOrder}
          onSort={(sort) =>
            !!onSort &&
            onSort(sort as 'cpf' | 'name' | 'phone' | 'email' | 'createdAt')
          }
          order={order}
          sortBy={sortBy}
          sortByOptions={sortByOptions}
        />

        {typeof totalItems === 'number' && (
          <Text fontSize="lg" mr="8">{`Total: ${totalItems}`}</Text>
        )}
      </Flex>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th whiteSpace="nowrap" textAlign="center">
              Face ID
            </Th>

            <Th>Hóspede</Th>

            <Th>Experiência</Th>

            <Th>Empreendimento</Th>

            <Th>Telefone</Th>

            <Th>E-mail</Th>

            <Th>Documento</Th>

            <Th textAlign="center">Data de cadastro</Th>

            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {lodgers.map((lodger) => (
            <Tr key={lodger.id}>
              <Td textAlign="center">
                <FaceIdStatusBadge
                  faceIdStatus={lodger.faceIdStatus}
                  avatarStatus={lodger.avatarStatus}
                />
              </Td>

              <Td>
                <Text fontWeight="bold">{lodger.name}</Text>
              </Td>

              <Td>{UserExperience[lodger.experience]}</Td>

              <Td>{lodger.venture?.name || '-'}</Td>

              <Td>{lodger.phone}</Td>

              <Td>{lodger.email}</Td>

              <Td>
                {lodger.foreignerDocumentType && lodger.foreignerDocument ? (
                  <VStack spacing="2">
                    <Text>{lodger.foreignerDocument}</Text>

                    <Text fontSize="sm" color="gray.400">
                      {
                        FriendlyForeignerUserDocumentType[
                          lodger.foreignerDocumentType
                        ]
                      }
                    </Text>
                  </VStack>
                ) : (
                  <VStack spacing="2">
                    <Text>{lodger.cpf}</Text>

                    <Text fontSize="sm" color="gray.400">
                      CPF
                    </Text>
                  </VStack>
                )}
              </Td>

              <Td textAlign="center">{lodger.formattedDate}</Td>

              <Td textAlign="right">
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/lodgers/details',
                    state: {
                      lodgerId: lodger.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
