import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { useAuth } from '../../../../../hooks/auth';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import deleteUserAvatarsService from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import {
  maskCpf,
  maskZipCode,
} from '../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../utils/errors';
import {
  validateCpf,
  validateBRPhone,
} from '../../../../../utils/documentValidation';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { UserExperience } from '../../../../../models/users';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';
import { Switch } from '../../../../../components/Form/Switch';

type UpdateGuestFormData = {
  address: IAddressBase;
  bio?: string;
  birthDate: Date;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  name: string;
  foreignerDocument?: string;
  foreignerDocumentType?: string;
  useForeignerDocument: boolean;
  phone?: string;
};

interface ILocationState {
  guestId: string;
}

const guestUpdateFormSchema = Yup.object().shape({
  address: addressValidation
    .nullable()
    .transform((value, originalValue) =>
      !!originalValue &&
      Object.values<string | undefined>(originalValue).some(
        (val) => !!val?.length,
      )
        ? originalValue
        : null,
    ),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  birthDate: Yup.date().nullable().required('Data de nascimento requerida'),
  cpf: Yup.string().when('useForeignerDocument', {
    is: (val: boolean) => val === false,
    then: Yup.string()
      .length(11, 'Valor inválido, requerido 11 dígitos')
      .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      )
      .required('CPF requerido'),
    otherwise: Yup.string()
      .nullable()
      .transform(() => null),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 5'],
      UserExperience['Nível 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience['Nível 8'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  useForeignerDocument: Yup.boolean().default(false),
  name: Yup.string()
    .required('Nome requerido')
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'Nome inválido'),
  foreignerDocument: Yup.string().when('useForeignerDocument', {
    is: (val: boolean) => val === true,
    then: Yup.string().when('foreignerDocumentType', {
      is: (val: string) => val === 'PASSPORT',
      then: Yup.string()
        .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
        .nullable()
        .transform((_, originalValue) =>
          originalValue.length ? originalValue : null,
        )
        .uppercase()
        .required('Passaporte requerido'),
      otherwise: Yup.string().when('foreignerDocumentType', {
        is: (val: string) => val === 'RNE',
        then: Yup.string()
          .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
          .nullable()
          .min(8, 'Mínimo 8 dígitos')
          .max(12, 'Máximo 12 dígitos')
          .transform((_, originalValue) =>
            originalValue.length ? originalValue : null,
          )
          .uppercase()
          .required('RNE requerido'),
        otherwise: Yup.string().when('foreignerDocumentType', {
          is: (val: string) => val === 'DRIVERS_LICENSE',
          then: Yup.string()
            .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
            .nullable()
            .transform((_, originalValue) =>
              originalValue.length ? originalValue : null,
            )
            .uppercase()
            .required('Carteira de motorista requerida'),
          otherwise: Yup.string()
            .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
            .nullable()
            .transform((_, originalValue) =>
              originalValue.length ? originalValue : null,
            )
            .uppercase()
            .required('Documento requerido'),
        }),
      }),
    }),
  }),
  foreignerDocumentType: Yup.string().when('useForeignerDocument', {
    is: (val: boolean) => val === true,
    then: Yup.string()
      .nullable()
      .transform((_, originalValue) =>
        originalValue.length ? originalValue : null,
      )
      .required('Tipo do documento requerido'),
    otherwise: Yup.string()
      .nullable()
      .transform(() => null),
  }),
  phone: Yup.string()
    .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const GuestUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();
  const { updateUser, user: authenticatedUser } = useAuth();

  const { state } = useLocation<ILocationState>();

  const [updatingGuest, setUpdatingGuest] = useState<IDetailedUser>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const formMethods = useForm({
    resolver: yupResolver(guestUpdateFormSchema),
  });

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    watch,
    setValue,
    clearErrors,
  } = formMethods;

  const { errors } = formState;
  const { guestId } = state;

  const isForeigner = watch('useForeignerDocument');
  const foreignerDocType = watch('foreignerDocumentType');

  const foreignerDocumentTypeSelectOptions = useMemo(
    () => [
      {
        label: 'RNE',
        value: 'RNE',
      },
      {
        label: 'Passaporte',
        value: 'PASSPORT',
      },
      {
        label: 'Carteira de motorista int.',
        value: 'DRIVERS_LICENSE',
      },
      {
        label: 'Outros',
        value: 'OTHERS',
      },
    ],
    [],
  );

  const experienceSelectOptions = useMemo(
    () => [
      {
        label: 'Nível 1 e 2',
        value: UserExperience['Nível 1 e 2'],
      },
      {
        label: 'Nível 3 e 4',
        value: UserExperience['Nível 3 e 4'],
      },
      {
        label: 'Nível 5',
        value: UserExperience['Nível 5'],
      },
      {
        label: 'Nível 6',
        value: UserExperience['Nível 6'],
      },
      {
        label: 'Nível 6 e 7',
        value: UserExperience['Nível 6 e 7'],
      },
      {
        label: 'Nível 7',
        value: UserExperience['Nível 7'],
      },
      {
        label: 'Nível 8',
        value: UserExperience['Nível 8'],
      },
      {
        label: 'Especial',
        value: UserExperience.Especial,
      },
    ],
    [],
  );

  useEffect(() => {
    async function loadGuest(): Promise<void> {
      try {
        const guestData = await showUsersService(guestId);
        setAvatarUrl(guestData.avatarUrl || undefined);

        setUpdatingGuest(guestData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do convidado, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadGuest();
  }, [guestId, reset, toast]);

  useEffect(() => {
    if (updatingGuest) {
      reset({
        address: {
          city: updatingGuest.address?.city,
          complement: updatingGuest.address?.complement,
          country: updatingGuest.address?.country,
          neighborhood: updatingGuest.address?.neighborhood,
          number: updatingGuest.address?.number,
          zipCode: updatingGuest.address?.zipCode
            ? maskZipCode(updatingGuest.address?.zipCode)
            : undefined,
          state: updatingGuest.address?.state,
          street: updatingGuest.address?.street,
        },
        bio: updatingGuest.bio,
        birthDate: updatingGuest.birthDate
          ? new Date(updatingGuest.birthDate)
          : undefined,
        cpf: updatingGuest.cpf ? maskCpf(updatingGuest.cpf) : undefined,
        email: updatingGuest.email,
        experience: updatingGuest.experience,
        healthCheck: updatingGuest.healthCheck
          ? new Date(updatingGuest.healthCheck)
          : undefined,
        useForeignerDocument: updatingGuest.useForeignerDocument,
        name: updatingGuest.name,
        foreignerDocument: updatingGuest.foreignerDocument,
        foreignerDocumentType: updatingGuest.foreignerDocumentType,
        phone: updatingGuest.phone,
      });
    }
  }, [reset, updatingGuest]);

  useEffect(() => {
    if (isForeigner) {
      setValue('cpf', '');
    }

    if (!isForeigner) {
      setValue('foreignerDocument', '');
      setValue('foreignerDocumentType', '');
    }
  }, [isForeigner, setValue]);

  useEffect(() => {
    clearErrors('foreignerDocument');
  }, [foreignerDocType, clearErrors]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(guestId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();

    if (guestId === authenticatedUser.id) {
      delete authenticatedUser.avatar;
      delete authenticatedUser.avatarUrl;

      updateUser(authenticatedUser);
    }
  }, [
    guestId,
    handleToggleDeleteConfirmationModal,
    updateUser,
    authenticatedUser,
  ]);

  const handleUpdateGuest: SubmitHandler<UpdateGuestFormData> = useCallback(
    async ({
      address,
      bio,
      birthDate,
      cpf,
      email,
      experience,
      healthCheck,
      name,
      foreignerDocument,
      foreignerDocumentType,
      useForeignerDocument,
      phone,
    }) => {
      try {
        const updatedGuest = await updateUsersService({
          userId: guestId,
          address,
          bio,
          birthDate,
          cpf,
          email,
          experience,
          healthCheck,
          name,
          foreignerDocument,
          foreignerDocumentType,
          useForeignerDocument,
          phone,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          const guestWithUpdatedAvatar = await updateUserAvatarsService({
            userId: guestId,
            avatarData: formData,
          });

          if (guestWithUpdatedAvatar.id === authenticatedUser.id) {
            updateUser(guestWithUpdatedAvatar);
          }
        } else if (updatedGuest.id === authenticatedUser.id) {
          updateUser(updatedGuest);
        }

        toast({
          title: 'Editado com sucesso',
          description: 'O convidado foi editado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao editar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao editar o convidado, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [guestId, avatar, authenticatedUser.id, toast, goBack, updateUser],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <FormProvider {...formMethods}>
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleUpdateGuest)}
        >
          <Heading size="lg" fontWeight="normal">
            Editar convidado
          </Heading>

          <Divider my="6" borderColor="gray.300" />

          <Flex justify="center" mb="8">
            <AvatarDropzone
              avatarUrl={avatarUrl}
              onChange={handleChangeAvatar}
              onDelete={handleToggleDeleteConfirmationModal}
            />
          </Flex>

          <VStack spacing="8">
            <MaskedInput
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />

            <Grid gap="4" templateColumns="repeat(12, 1fr)" w="full">
              <GridItem
                colSpan={!isForeigner ? [12, 8, 9, 9, 10] : [12, 12, 12, 9, 10]}
              >
                {!isForeigner ? (
                  <MaskedInput
                    label="CPF"
                    mask="cpf"
                    error={errors.cpf}
                    {...register('cpf')}
                  />
                ) : (
                  <Grid
                    templateColumns="repeat(12, 1fr)"
                    gap={[6, 6, 0]}
                    w="full"
                  >
                    <GridItem colSpan={[12, 12, 5]}>
                      <ReactSelect
                        label="Tipo"
                        name="foreignerDocumentType"
                        options={foreignerDocumentTypeSelectOptions}
                        control={control}
                        error={errors.foreignerDocumentType}
                      />
                    </GridItem>

                    <GridItem colSpan={[12, 12, 7]}>
                      <MaskedInput
                        label="Documento"
                        error={errors.foreignerDocument}
                        {...register('foreignerDocument')}
                      />
                    </GridItem>
                  </Grid>
                )}
              </GridItem>

              <GridItem
                colSpan={!isForeigner ? [12, 4, 3, 3, 2] : [12, 12, 12, 3, 2]}
              >
                {!!updatingGuest && (
                  <Switch
                    label="Usar documento internacional"
                    error={errors.useForeignerDocument}
                    {...register('useForeignerDocument')}
                  />
                )}
              </GridItem>
            </Grid>

            <SimpleGrid minChildWidth="240px" spacing="6" w="full">
              <InternationalPhoneInput
                flex={1}
                label="Telefone"
                name="phone"
                control={control}
                error={errors.phone}
              />

              <MaskedInput
                label="E-mail"
                type="email"
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />
            </SimpleGrid>

            <SimpleGrid minChildWidth="240px" spacing="6" w="full">
              <DatePicker
                label="Data de nascimento"
                isClearable={false}
                maxDate={new Date()}
                control={control}
                error={errors.birthDate}
                {...register('birthDate')}
              />

              <DatePicker
                label="Exame médico"
                maxDate={new Date()}
                control={control}
                error={errors.healthCheck}
                {...register('healthCheck')}
              />

              <ReactSelect
                label="Nível de experiência"
                name="experience"
                options={experienceSelectOptions}
                control={control}
                error={errors.experience}
              />
            </SimpleGrid>

            <MaskedInput
              label="Biografia"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.bio}
              {...register('bio')}
            />
          </VStack>

          <Divider my="6" borderColor="gray.300" />

          <AddressForm />

          <Flex mt="12" justify="flex-end">
            <ButtonGroup>
              <Button colorScheme="blackAlpha" onClick={goBack}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      </FormProvider>
    </DefaultLayout>
  );
};
