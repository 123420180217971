import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Text,
  Box,
  HStack,
  IconButton,
  Icon,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useCalendarEvents } from '../../../../../../../../../../../../../hooks/calendarEvent';
import { WaveSideEnum } from '../../../../../../../../../../../../../models/activities';
import { BookingPaymentStatus } from '../../../../../../../../../../../../../models/bookings';
import { UserExperience } from '../../../../../../../../../../../../../models/users';
import { IWeekdayActivitySchedule } from '../../../../../../../../../../../../../services/Activities/ListWeekdaysActivitySchedulesService';
import { IBookingExternalPayment } from '../../../../../../../../../../../../../services/Bookings/CreateBookingExternalPaymentsService';
import {
  ICreateCheckin,
  IScheduleBooking,
  ScheduleBookingsTable,
} from './components/ScheduleBookingsTable';
import { BookingQueueTable } from '../../../ActivitySchedules/components/ActivityScheduleBookingQueueModal/components/BookingQueueTable';
import { useAuth } from '../../../../../../../../../../../../../hooks/auth';

const formatDay = (date: Date): string =>
  date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
  });

const formatWeekDay = (date: Date): string =>
  date.toLocaleDateString('pt-BR', {
    weekday: 'long',
  });

const formatTime = (date: Date): string =>
  date.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });

type ScheduleBookingsModalProps = {
  eventId: string;
  isOpen: boolean;
  onClose: () => void;
  onCreateBooking: (schedule: IWeekdayActivitySchedule) => void;
};

export default function ScheduleBookingsModal({
  eventId,
  isOpen,
  onClose,
  onCreateBooking,
}: ScheduleBookingsModalProps): JSX.Element {
  const [tabIndex, setTabIndex] = useState(0);
  const { calendarEvents, handleCalendarEvents } = useCalendarEvents();

  const { user: authenticatedUser } = useAuth();

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const event = useMemo(
    () => calendarEvents.find((ev) => ev.id === eventId),
    [calendarEvents, eventId],
  );

  if (!event) {
    onClose();

    return <></>;
  }

  const handleCreateCheckin = ({ booking, checkin }: ICreateCheckin): void => {
    handleCalendarEvents(
      calendarEvents.map((calendarEvent) =>
        calendarEvent.id === event.id
          ? {
              ...calendarEvent,
              schedules: calendarEvent.schedules.map((eventSchedule) =>
                eventSchedule.activityScheduleId === booking.activityScheduleId
                  ? {
                      ...eventSchedule,
                      bookings: eventSchedule.bookings.map((scheduleBooking) =>
                        scheduleBooking.id === checkin.bookingId
                          ? {
                              ...scheduleBooking,
                              checkins: [checkin, ...scheduleBooking.checkins],
                            }
                          : scheduleBooking,
                      ),
                    }
                  : eventSchedule,
              ),
            }
          : calendarEvent,
      ),
    );
  };

  const handleDeleteBooking = (booking: IScheduleBooking): void => {
    handleCalendarEvents(
      calendarEvents.map((calendarEvent) => {
        const schedules = calendarEvent.schedules.map((eventSchedule) => {
          const bookings = eventSchedule.bookings.filter(
            (scheduleBooking) => scheduleBooking.id !== booking.id,
          );

          if (eventSchedule.activityScheduleId === booking.activityScheduleId) {
            const response = {
              ...eventSchedule,
              booked: bookings.length,
              bookings,
            };

            if (booking.user.featureGroup.key.includes('GUEST')) {
              response.guestBooked -= 1;
            }

            if (booking.user.featureGroup.key.includes('LODGER')) {
              response.lodgerBooked -= 1;
            }

            return response;
          }

          return eventSchedule;
        });

        const eventVacancies = schedules.reduce(
          (vacancies, e) => vacancies + e.vacancies - e.booked,
          0,
        );

        if (calendarEvent.id === event.id) {
          return {
            ...calendarEvent,
            title: eventVacancies > 0 ? `${eventVacancies} vaga(s)` : 'Lotado',
            isFull: eventVacancies <= 0,
            schedules,
          };
        }

        return calendarEvent;
      }),
    );
  };

  const handlePayBooking = (booking: IBookingExternalPayment): void => {
    handleCalendarEvents(
      calendarEvents.map((calendarEvent) =>
        calendarEvent.id === event.id
          ? {
              ...calendarEvent,
              schedules: calendarEvent.schedules.map((eventSchedule) =>
                eventSchedule.activityScheduleId === booking.activityScheduleId
                  ? {
                      ...eventSchedule,
                      bookings: eventSchedule.bookings.map((scheduleBooking) =>
                        scheduleBooking.id === booking.id
                          ? {
                              ...scheduleBooking,
                              payment: scheduleBooking.payment
                                ? {
                                    ...scheduleBooking.payment,
                                    status:
                                      BookingPaymentStatus.PAYMENT_EXTERNAL,
                                  }
                                : undefined,
                            }
                          : scheduleBooking,
                      ),
                    }
                  : eventSchedule,
              ),
            }
          : calendarEvent,
      ),
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="7xl">
        <ModalOverlay />

        <ModalContent maxH="88vh">
          <ModalHeader>
            {`Dia ${formatDay(event.start)} - ${formatWeekDay(
              event.start,
            )} | ${formatTime(event.start)} - ${formatTime(event.end)}`}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody overflow="auto">
            <SimpleGrid columns={3} spacing="2" w="100%">
              {event.schedules.map((schedule) => {
                const availableVacancies = schedule.vacancies - schedule.booked;

                const guestAvailableVacancies =
                  schedule.guestVacancies - schedule.guestBooked;

                const lodgerAvailableVacancies =
                  schedule.lodgerVacancies - schedule.lodgerBooked;

                return (
                  <Box
                    key={schedule.activityScheduleId}
                    borderWidth={1}
                    borderRadius="8"
                    p="2"
                  >
                    <HStack justify="space-between">
                      <Text as="strong">
                        {UserExperience[schedule.experience]}
                      </Text>

                      {!schedule.isExpired && (
                        <IconButton
                          aria-label="create-booking"
                          size="xs"
                          icon={<Icon as={RiAddLine} fontSize="18" />}
                          fontSize="16"
                          colorScheme="green"
                          onClick={() => onCreateBooking(schedule)}
                        />
                      )}
                    </HStack>

                    <Box>
                      {schedule.modalityTitle && (
                        <p>
                          <Text as="span">Modalidade: </Text>
                          <Text as="span">{schedule.modalityTitle}</Text>
                        </p>
                      )}

                      {!!schedule.waveSide && (
                        <p>
                          <Text as="span">Onda: </Text>
                          <Text as="span">
                            {WaveSideEnum[schedule.waveSide]}
                          </Text>
                        </p>
                      )}

                      <Text>{`${schedule.booked} reservas`}</Text>

                      <HStack spacing="1" fontSize="sm">
                        {authenticatedUser.featureGroup.features.some(
                          (feature) =>
                            feature.key === 'LODGER_VACANCIES_FULL_ACCESS',
                        ) && (
                          <>
                            <Text>
                              {availableVacancies >= 0 ? availableVacancies : 0}
                            </Text>

                            <Text>vagas disponíveis</Text>

                            {availableVacancies < 0 && (
                              <Text color="gray.500">
                                {`${
                                  availableVacancies * -1
                                } reservado após limite`}
                              </Text>
                            )}
                          </>
                        )}
                      </HStack>

                      <HStack spacing="1" fontSize="sm">
                        {authenticatedUser.featureGroup.features.some(
                          (feature) =>
                            feature.key === 'LODGER_VACANCIES_FULL_ACCESS',
                        ) && (
                          <>
                            <Text>
                              {availableVacancies > 0 &&
                              guestAvailableVacancies >= 0
                                ? guestAvailableVacancies
                                : 0}
                            </Text>

                            <Text>vagas disp. convidados</Text>

                            {guestAvailableVacancies < 0 && (
                              <Text color="gray.500">
                                {`${
                                  guestAvailableVacancies * -1
                                } reservado após limite`}
                              </Text>
                            )}
                          </>
                        )}
                      </HStack>

                      <HStack spacing="1" fontSize="sm">
                        {authenticatedUser.featureGroup.features.some(
                          (feature) =>
                            feature.key === 'LODGER_VACANCIES_FULL_ACCESS',
                        ) && (
                          <>
                            <Text>
                              {availableVacancies > 0 &&
                              lodgerAvailableVacancies >= 0
                                ? lodgerAvailableVacancies
                                : 0}
                            </Text>

                            <Text>vagas disp. hóspedes</Text>

                            {schedule.isLodgerVacanciesExclusive && (
                              <Text color="gray.500">(exclusivas)</Text>
                            )}

                            {lodgerAvailableVacancies < 0 && (
                              <Text color="gray.500">
                                {`${
                                  lodgerAvailableVacancies * -1
                                } reservado após limite`}
                              </Text>
                            )}
                          </>
                        )}
                      </HStack>
                    </Box>
                  </Box>
                );
              })}
            </SimpleGrid>

            <Tabs
              index={tabIndex}
              isLazy
              onChange={handleTabChanges}
              variant="enclosed-colored"
              mt="6"
            >
              <TabList>
                <Tab>Reservas</Tab>

                <Tab>Fila de espera</Tab>
              </TabList>

              <TabPanels mt="4">
                <TabPanel>
                  <ScheduleBookingsTable
                    schedules={event.schedules}
                    onCreateCheckin={handleCreateCheckin}
                    onDeleteBooking={handleDeleteBooking}
                    onPayBooking={handlePayBooking}
                  />
                </TabPanel>

                <TabPanel>
                  {event.schedules.length > 1 ? (
                    event.schedules.map((schedule) => (
                      <>
                        {schedule.waveSide && (
                          <Box>
                            <Text as="span" fontWeight="bold" pr="2">
                              Lado da onda:
                            </Text>
                            <Text as="span">
                              {WaveSideEnum[schedule.waveSide]}
                            </Text>
                          </Box>
                        )}

                        {schedule.experience >= 0 && (
                          <Box>
                            <Text as="span" fontWeight="bold" pr="2">
                              Experiência:
                            </Text>
                            <Text as="span">
                              {UserExperience[schedule.experience]}
                            </Text>
                          </Box>
                        )}

                        {schedule.modalityTitle && (
                          <Box>
                            <Text as="span" fontWeight="bold" pr="2">
                              Modalidade:
                            </Text>
                            <Text as="span">{schedule.modalityTitle}</Text>
                          </Box>
                        )}

                        <BookingQueueTable
                          flex={1}
                          activityScheduleId={schedule.activityScheduleId}
                          bookedDate={event.start}
                          mb="10"
                        />
                      </>
                    ))
                  ) : (
                    <BookingQueueTable
                      flex={1}
                      activityScheduleId={event.schedules[0].activityScheduleId}
                      bookedDate={event.start}
                      mb="10"
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
}
