import { Switch } from 'react-router-dom';
import { MemberDetails } from '../pages/App/Adm/Members/MemberDetails';
import { MemberList } from '../pages/App/Adm/Members/MemberList';
import { MemberRegister } from '../pages/App/Adm/Members/MemberRegister';
import { MemberUpdate } from '../pages/App/Adm/Members/MemberUpdate';
import { GuestDetails } from '../pages/App/Adm/Guests/GuestDetails';
import { GuestList } from '../pages/App/Adm/Guests/GuestList';
import { GuestRegister } from '../pages/App/Adm/Guests/GuestRegister';
import { GuestUpdate } from '../pages/App/Adm/Guests/GuestUpdate';
import { SpotDetails } from '../pages/App/Adm/Spots/SpotDetails';
import { SpotList } from '../pages/App/Adm/Spots/SpotList';
import { SpotRegister } from '../pages/App/Adm/Spots/SpotRegister';
import { SpotUpdate } from '../pages/App/Adm/Spots/SpotUpdate';
import { UserDetails } from '../pages/App/Adm/Users/UserDetails';
import { UserList } from '../pages/App/Adm/Users/UserList';
import { UserRegister } from '../pages/App/Adm/Users/UserRegister';
import { UserUpdate } from '../pages/App/Adm/Users/UserUpdate';
import { VentureDetails } from '../pages/App/Adm/Ventures/VentureDetails';
import { VentureList } from '../pages/App/Adm/Ventures/VentureList';
import { VentureRegister } from '../pages/App/Adm/Ventures/VentureRegister';
import { VentureUpdate } from '../pages/App/Adm/Ventures/VentureUpdate';
import { ActivityDetails } from '../pages/App/General/Activities/ActivityDetails';
import { ActivityList } from '../pages/App/General/Activities/ActivityList';
import { ActivityRegister } from '../pages/App/General/Activities/ActivityRegister';
import { ActivityUpdate } from '../pages/App/General/Activities/ActivityUpdate';
import { BookingDetails } from '../pages/App/General/Bookings/BookingDetails';
import { BookingList } from '../pages/App/General/Bookings/BookingList';
import { Dashboard } from '../pages/App/General/Dashboard';
import { Profile } from '../pages/App/Profile';
import { FeatureGroupList } from '../pages/App/Settings/FeatureGroups/FeatureGroupList';
import { HandleFeatureGroup } from '../pages/App/Settings/FeatureGroups/HandleFeatureGroup';
import { FeatureList } from '../pages/App/Settings/Features/FeatureList';
import { PasswordResetConfirm } from '../pages/Auth/PasswordResetConfirm';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { ResetPassword } from '../pages/Auth/ResetPassword';
import { SignupConfirm } from '../pages/Auth/SignupConfirm';
import { SignIn } from '../pages/Auth/SignIn';
import { Signup } from '../pages/Auth/Signup';
import { Route } from './Route';
import { BookingQueueDetails } from '../pages/App/General/BookingQueues/BookingQueueDetails';
import { BookingQueueList } from '../pages/App/General/BookingQueues/BookingQueueList';
import { LodgerList } from '../pages/App/Adm/Lodgers/LodgerList';
import { LodgerDetails } from '../pages/App/Adm/Lodgers/LodgerDetails';
import { LodgerRegister } from '../pages/App/Adm/Lodgers/LodgerRegister';
import { LodgerUpdate } from '../pages/App/Adm/Lodgers/LodgerUpdate';

export const Routes = (): JSX.Element => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" exact component={Signup} />
    <Route path="/signup/confirm" component={SignupConfirm} />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/profile" exact component={Profile} isPrivate />

    <Route path="/users" exact component={UserList} isPrivate />
    <Route path="/users/details" component={UserDetails} isPrivate />
    <Route path="/users/register" component={UserRegister} isPrivate />
    <Route path="/users/update" component={UserUpdate} isPrivate />
    <Route path="/users/passwords/forgot" component={ForgotPassword} />
    <Route path="/users/passwords/reset" component={ResetPassword} />
    <Route path="/users/passwords/confirm" component={PasswordResetConfirm} />

    <Route path="/members" exact component={MemberList} isPrivate />
    <Route path="/members/details" exact component={MemberDetails} isPrivate />
    <Route
      path="/members/register"
      exact
      component={MemberRegister}
      isPrivate
    />
    <Route path="/members/update" exact component={MemberUpdate} isPrivate />

    <Route path="/lodgers" exact component={LodgerList} isPrivate />
    <Route path="/lodgers/details" exact component={LodgerDetails} isPrivate />
    <Route
      path="/lodgers/register"
      exact
      component={LodgerRegister}
      isPrivate
    />
    <Route path="/lodgers/update" exact component={LodgerUpdate} isPrivate />

    <Route path="/ventures" exact component={VentureList} isPrivate />
    <Route path="/ventures/details" component={VentureDetails} isPrivate />
    <Route path="/ventures/register" component={VentureRegister} isPrivate />
    <Route path="/ventures/update" component={VentureUpdate} isPrivate />

    <Route path="/activities" exact component={ActivityList} isPrivate />
    <Route path="/activities/details" component={ActivityDetails} isPrivate />
    <Route path="/activities/register" component={ActivityRegister} isPrivate />
    <Route path="/activities/update" component={ActivityUpdate} isPrivate />

    <Route path="/bookings" exact component={BookingList} isPrivate />
    <Route
      path="/booking-queues"
      exact
      component={BookingQueueList}
      isPrivate
    />
    <Route path="/bookings/details" component={BookingDetails} isPrivate />

    <Route
      path="/booking-queues/details"
      exact
      component={BookingQueueDetails}
      isPrivate
    />

    <Route path="/features" component={FeatureList} isPrivate exact />

    <Route
      path="/feature-groups/management"
      component={HandleFeatureGroup}
      isPrivate
    />
    <Route
      path="/feature-groups"
      component={FeatureGroupList}
      isPrivate
      exact
    />

    <Route path="/guests" exact component={GuestList} isPrivate />
    <Route path="/guests/details" component={GuestDetails} isPrivate />
    <Route path="/guests/register" component={GuestRegister} isPrivate />
    <Route path="/guests/update" component={GuestUpdate} isPrivate />

    <Route path="/spots" exact component={SpotList} isPrivate />
    <Route path="/spots/details" component={SpotDetails} isPrivate />
    <Route path="/spots/register" component={SpotRegister} isPrivate />
    <Route path="/spots/update" component={SpotUpdate} isPrivate />
  </Switch>
);
