import { Box, Heading, useToast, Icon, HStack } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import { maskShortDate } from '../../../../../utils/formatters/handleMask';
import { IActivity, ActivityTable } from './components/ActivityTable';
import { listActivitiesService } from '../../../../../services/Activities/ListActivitiesService';
import { translateError } from '../../../../../utils/errors';
import { useActivity } from '../../../../../hooks/activity';
import { useAuth } from '../../../../../hooks/auth';
import { listSpotsService } from '../../../../../services/Spots/ListSpotsService';
import { SelectOption } from '../../../../../components/Form/ReactSelect';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import { AsyncSelectOption } from '../../../../../components/Form/AsyncSelect';

interface ILoadActivitiesProps {
  spotId?: string;
  ventureId?: string;
  name?: string;
  sort?: 'name' | 'isActive' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const ActivityList = (): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { handleActivity } = useActivity();

  const [activitiesList, setActivitiesList] = useState<IActivity[]>([]);
  const [filterByOptions, setFilterByOptions] = useState<SelectOption[]>([
    { label: 'Nome', value: 'name' },
    { label: 'Local', value: 'spotId' },
  ]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<'name' | 'isActive' | 'createdAt'>(
    'createdAt',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadActivities = useCallback(
    async ({
      name,
      order,
      page,
      sort,
      spotId,
      ventureId,
    }: ILoadActivitiesProps) => {
      try {
        const activities = await listActivitiesService({
          name,
          limit: 20,
          page,
          sort,
          order,
          spotId,
          ventureId,
        });

        const parsedActivities = activities.items.map((activity) => ({
          ...activity,
          formattedCreatedAt: maskShortDate(activity.createdAt),
        }));

        setTotalPages(activities.pages);
        setActivitiesList(parsedActivities);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos atividades, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    if (!authenticatedUser.ventureId) {
      setFilterByOptions([
        { label: 'Nome', value: 'name' },
        { label: 'Local', value: 'spotId' },
        { label: 'Empreendimento', value: 'ventureId' },
      ]);
    }

    loadActivities({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentFilters,
    currentPage,
    sortBy,
    loadActivities,
    currentOrdering,
    handleActivity,
    authenticatedUser.ventureId,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
    setCurrentFilters(undefined);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'name' | 'isActive' | 'createdAt') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleLoadFilterOptions = useCallback(
    async (name: string): Promise<AsyncSelectOption[]> => {
      switch (filterBy) {
        case 'ventureId': {
          if (authenticatedUser.ventureId) {
            return [];
          }

          const { items: ventures } = await listVenturesService({ name });

          const venturesSelectOptions: AsyncSelectOption[] = ventures.map(
            (venture) => ({
              label: venture.name,
              value: venture.id,
            }),
          );

          return venturesSelectOptions;
        }

        case 'spotId': {
          const { items: spots } = await listSpotsService({
            isActive: true,
            name,
          });

          const spotsSelectOptions: AsyncSelectOption[] = spots.map((spot) => ({
            label: spot.name,
            value: spot.id,
          }));

          return spotsSelectOptions;
        }

        default:
          return [];
      }
    },
    [filterBy, authenticatedUser.ventureId],
  );

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <HStack justify="space-between">
          <Heading size="lg" fontWeight="normal">
            Atividades
          </Heading>

          {authenticatedUser.featureGroup.features.some((feature) =>
            ['ACTIVITY_FULL_ACCESS', 'ACTIVITY_WRITE_ACCESS'].includes(
              feature.key,
            ),
          ) && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/activities/register"
            >
              Cadastrar nova
            </LinkButton>
          )}
        </HStack>

        <ActivityTable
          mt="4"
          activities={activitiesList}
          currentPage={currentPage}
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          loadAsyncOptions={handleLoadFilterOptions}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          order={currentOrdering}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
