import { IAddressEntity } from './address';
import { IEntityBase } from './entityBase';

export enum UserFaceIdStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum UserAvatarStatus {
  EMPTY = 'EMPTY',
  VALID = 'VALID',
  INVALID = 'INVALID',
  NOT_VERIFIED = 'NOT_VERIFIED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum UserProfileStatusColor {
  CONFIRMED = 'green.500',
  PENDING = 'red.500',
}

export enum UserProfileStatus {
  CONFIRMED = 'Confirmado',
  PENDING = 'Pendente',
}

export enum UserExperience {
  'Nível 1 e 2',
  'Nível 3 e 4',
  'Nível 5',
  'Nível 6',
  'Nível 6 e 7',
  'Nível 7',
  'Nível 8',
  'Especial',
}

export enum UserGender {
  FEMALE = 'Feminino',
  MALE = 'Masculino',
  OTHER = 'Outros',
}

export type ForeignerUserDocumentType =
  | 'RNE'
  | 'PASSPORT'
  | 'DRIVERS_LICENSE'
  | 'OTHERS';

export enum FriendlyForeignerUserDocumentType {
  RNE = 'RNE',
  PASSPORT = 'Passaporte',
  DRIVERS_LICENSE = 'Carteira de motorista int.',
  OTHERS = 'Outros',
}

export interface IUserAddressBase extends IAddressEntity {
  userId: string;
}

export interface IUserBase extends IEntityBase {
  avatar?: string;
  avatarUrl?: string;
  age?: number;
  bio?: string;
  birthDate?: string;
  blockedUntil?: string;
  categoryId?: string;
  confirmPending: boolean;
  cpf?: string;
  cnpj?: string;
  companyName?: string;
  email: string;
  experience: UserExperience;
  featureGroupId?: string;
  gender?: UserGender;
  healthCheck?: string;
  hostId?: string;
  isBlocked: boolean;
  name: string;
  onboardingPending: boolean;
  onboardingToken?: string;
  onboardingUrl?: string;
  foreignerDocument?: string;
  foreignerDocumentType?: ForeignerUserDocumentType;
  useForeignerDocument: boolean;
  paymentRegistered: boolean;
  phone?: string;
  tradeName?: string;
  ventureId?: string;
  avatarStatus: UserAvatarStatus;
  faceIdStatus: UserFaceIdStatus;
}
